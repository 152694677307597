import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Android Voting" link="https://github.com/cam292/android_voting" bg="linear-gradient(to right, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
Android app to aggregate text message votes.
    </ProjectCard>
    <ProjectCard title="Red Cups" link="https://github.com/zacharymell/RedCups" bg="linear-gradient(to left, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
Android app with a suite of pass-and-play games with the goal of bringing fun to social gatherings.
    </ProjectCard>
    <ProjectCard title="Micromouse" link="https://github.com/Pitt-RAS/micromouse-2016" bg="linear-gradient(to right, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
A maze solving robot.
    </ProjectCard>
    <ProjectCard title="BAO" link="https://github.com/BDANG/hold_em_or_fold" bg="linear-gradient(to left, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
Text notification system to alert of Bitcoin Arbitrage Opportunities
    </ProjectCard>
    <ProjectCard title="StudyConnect" link="https://github.com/jkellyjr/Study_Connect" bg="linear-gradient(to right, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
Web platform for students to share notes, create study groups, and discuss courses.
    </ProjectCard>
    <ProjectCard title="Adrians Pizza" link="https://github.com/jkellyjr/Study_Connect" bg="linear-gradient(to left, #D4145A 0%, #6600cc 100%)" mdxType="ProjectCard">
Web app for ordering pizza
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      