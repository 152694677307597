import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I’m a software engineer, ice hockey coach, cat dad, and gamer.  `}</p>
    <p>{`I have served as head the IT Department of the Mazzotta family for as long as I can remember and found my love for developing software in a high school robotics course.
Throughout my 18 years of competitive ice hockey (ending as an assistant captain of Pitt’s D1 club hockey team), I have learned the values of hard-work, teamwork, and leadership that have translated well into the software engineering field.`}</p>
    <p>{`I am passionate about writing clean, efficient code and designing elegant software systems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      