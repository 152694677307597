import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <p>{`Email: `}<a parentName="p" {...{
        "href": "mailto:mazzottacraig@gmail.com"
      }}>{`mazzottacraig@gmail.com`}</a><br parentName="p"></br>{`
`}{`LinkedIn: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/craig-mazzotta/"
      }}>{`https://www.linkedin.com/in/craig-mazzotta/`}</a><br parentName="p"></br>{`
`}{`Instagram: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/mazz_27/"
      }}>{`https://www.instagram.com/mazz_27/`}</a>{`  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      